import { default as _400NP99MOQmn2Meta } from "/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/400.vue?macro=true";
import { default as _500fNctzRBzdgMeta } from "/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/500.vue?macro=true";
import { default as contatoNDpk0UKm6eMeta } from "/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/contato.vue?macro=true";
import { default as casas_45bahiaVV51Pf8pvuMeta } from "/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/credor/casas-bahia.vue?macro=true";
import { default as digioNY0W95RyQkMeta } from "/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/credor/digio.vue?macro=true";
import { default as pontoAcoTUY5cueMeta } from "/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/credor/ponto.vue?macro=true";
import { default as empresarUXVpfCm5fMeta } from "/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/empresa.vue?macro=true";
import { default as indexdXNf1StqQsMeta } from "/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/index.vue?macro=true";
import { default as infoJ11Nxw47Y5Meta } from "/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/info.vue?macro=true";
export default [
  {
    name: "400",
    path: "/400",
    component: () => import("/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/400.vue").then(m => m.default || m)
  },
  {
    name: "500",
    path: "/500",
    component: () => import("/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/500.vue").then(m => m.default || m)
  },
  {
    name: "contato",
    path: "/contato",
    component: () => import("/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/contato.vue").then(m => m.default || m)
  },
  {
    name: "credor-casas-bahia",
    path: "/credor/casas-bahia",
    meta: casas_45bahiaVV51Pf8pvuMeta || {},
    component: () => import("/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/credor/casas-bahia.vue").then(m => m.default || m)
  },
  {
    name: "credor-digio",
    path: "/credor/digio",
    meta: digioNY0W95RyQkMeta || {},
    component: () => import("/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/credor/digio.vue").then(m => m.default || m)
  },
  {
    name: "credor-ponto",
    path: "/credor/ponto",
    meta: pontoAcoTUY5cueMeta || {},
    component: () => import("/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/credor/ponto.vue").then(m => m.default || m)
  },
  {
    name: "empresa",
    path: "/empresa",
    component: () => import("/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/empresa.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexdXNf1StqQsMeta || {},
    component: () => import("/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/codebuild/output/src2191269156/src/melembre-landing-page-v2/pages/info.vue").then(m => m.default || m)
  }
]